.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.App-logo {
  width: 100%;
  margin-bottom: 3rem;
  max-width: 320px;
}
